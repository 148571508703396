


.articleTitleContainer {
    display: flex;
    height: 65vh;
    overflow: hidden;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.articleImage {
    z-index: -2;
    width: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    height: 100vh;
}

.articleTitle {
    max-width: 1500px;
    width: 80%;
}

.articleDate{
    font-weight: 500;
    padding-left: 12%;
}

.articleName{
    display: flex;
    gap: 15px;
    margin-bottom: 3%;
    padding-left: 12%;
}

.articleName h1{
    margin: 0;
}


.articleTextContainer{
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50vh;
    flex-direction: column;
    margin-bottom: -2%;
    padding-top: 1%;
    backdrop-filter: blur(500px) brightness(20%);
}

.articleText{
    width: 50%;
    padding-bottom: 8%;
    max-width: 1000px;
}

.articleTextProper{
    line-height: 2em;
}

.articlePageTags{
    justify-content: flex-start;
    margin-bottom: 4%;
}

.articleGradient{
    position: absolute;
    bottom:0;
    height: 50vh;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgb(83, 120, 149, 0) 100%); 
    width: 100%;
    z-index: -1;
}


.backButton{
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    gap: 20px;
    pointer-events: auto;
    cursor: pointer;
    width: 80%;
    max-width: 1920px;
    filter: drop-shadow(0px 0px 8px #000000bd);
}

.backButton svg{
    padding-left: 6%;
}

.articleText svg{
    padding-left: 0;
}



.newsArticleList {
    flex-direction: column;
    padding-top: 3%;
    padding-bottom: 7%;
    margin-bottom: 7%;
}

.newsArticleListContainer{
    padding-left: 6%;
    padding-right: 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filters {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 1360px;
}

.backButton .hover-underline-animation::after{
    bottom: -3px;
}



@media all and (max-width: 1080px) {
    .articleTitleContainer {
        height: 40vh;
    }

    .articleImage {
        height: 60vh;
    }

    .articleText {
        width: 80%;
    }

    .articleGradient{
        height: 35vh;
    }

    .articleTitleContainer h1{
        font-size: 1.5em;
    }

    .backButton{
        margin-top: 9vh;
    }

    .backButtonArticleEnd{
        margin-left: -10px;
    } 

    .articleTitleContainer .backButton h3{
        visibility: hidden;
    }

    .backButton svg{
        padding-left: 0;
    }

    .articleDate, .articleName{
        padding-left: 0;
    }

    .articleTitle{
        margin-bottom: 2vh;
    }

    .articlePageTags{
        margin-top: 10%;
    }
}