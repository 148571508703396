/* @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --primary-red: #CB0000;
    --trans-black: #10101073;
    --articleCard-trans-black: #10101082;
    --primary-white: rgb(237, 237, 237);
    --border-white: #ffffff98;
    --thin-border-white: #ffffff3d;
    --bordered-background: #e1e1e110;
    --dark-blue: #171a1c;
    --blue-for-links: #61adff;

    --hover-width: 100%;
    --hover-height: 100%;

    --blur: 50px;
}

#root{
   /* overflow: hidden;*/
    min-height: 100vh;
    pointer-events: auto;
}

html{
  /*  overflow-x: hidden;*/
    background-color: black;
    height: 100%;
}

body.preload *{
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}

.no-blur{
    background-color: transparent !important;
    border: solid 1px var(--border-white) !important;
    backdrop-filter: blur(3px);
    background-color: #00000005;
}

.blur{
    -webkit-backdrop-filter: blur(var(--blur)) brightness(110%);
    backdrop-filter: blur(var(--blur)) brightness(110%);
    background-color: var(--bordered-background);
}

.no-border{
    border: none !important;
}

.section{
    display: flex;
    padding-top: 3%;
    padding-bottom: 5%;
    margin-bottom: 3%;
    max-width: 1920px;
    width: 80%;    
    border-radius: 18px;
    z-index: 2;
}



.container {
    padding-left: 6%;
    padding-right: 6%;
    display: flex;
    flex-direction: column;
  }

.centered{
    padding-left: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

body {
    margin: 0;
   /* overflow-y: hidden;
    overflow-x: hidden;*/
    position: relative; 
}

h1, h2, h3, h4, h5, p, button, li, svg, a {
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    color: var(--primary-white);
    letter-spacing: -0.25px;
}



p{
    line-height: 22px;
    font-weight: 300;
}

h5 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1em;
}

img{
    max-width: 100%;
}

a {
    color: var(--primary-white);
    text-decoration: underline;
    transition: color 0.15s;
}

a:visited {
    color: var(--primary-white);
}

a:hover {
    transition: color 0.25s;
    color: var(--blue-for-links);
}

.animated {
    -webkit-animation-duration: 0.5s !important;
    animation-duration: 0.5s !important;
}

.verticalLine {
    border-left: 1px solid var(--primary-white);
    height: 90px;
    margin-top: 1%;
    margin-bottom: 1%;
}

.horizontalLine {
    border-bottom: 2px solid var(--primary-red);
    width: 90px;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
}


.eventDivider{
    border-bottom: solid 1px var( --border-white);
    margin-top: 10%;  
    width: 100%;
}



/*---------------------------------------------------------------components---------------------------------------------------------------*/

.dropdown{
    height: 48px;
    width: 200px;
    border: solid 1px var(--primary-white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.dropdown p{
    margin: 0;
    margin-left: 7%
}

.dropdown-arrow{
    width:10px;
    padding: 10%;
    padding-top: 11%;
    padding-bottom: 11%;
}

.dropdown-menu{
    min-width: 150px;
    background-color: #e1e1e145;
    border-radius: 5px;
    position: absolute;
    margin-top: 5px;

    visibility: hidden;
    opacity:0;

    transition: visibility 0.15s ease-in-out,opacity 0.15s ease-in-out;
    z-index: 10;

    padding-top: 6px;
    padding-bottom: 6px;
    
}

.dropdown-menu p{
    margin: 0;
    margin-left: 8%;
}

.filterItem{
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.filterItem:hover{
    background-color: var(--trans-black);
}

.filterByContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:20px;
}

.filters{
    margin-bottom: 4%;
}

.lineGraphic{
    max-height: 100px;
}

.lineGraphic_red{
    max-width: 70px;
}

button p{
    margin: 0;
    font-weight: 300 !important;
}

.buttonContainer {
    position: relative;
}



.articleCardContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    flex: 0 1 370px;
    min-width: 275px;
    text-decoration: none;
    cursor: pointer;
}

.imageContainer{
    height: 284px;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    border: solid 2px transparent;
    transition: border 0.5s;
    flex: 1 1 300px;
}



.imageContainer img {
    max-width: 110%;
    transition: transform 0.5s;
    margin-left: 50%;
    transform: translateX(-50%);
    min-height: 100%;
    object-fit: cover;
}

/*------------------animation--------------------*/

button {
    background: none;
    background-color: transparent;
    border: 0;
    height: 45px;
    width: 200px;
    box-sizing: border-box;
    padding: 0;
    border: solid 1px;
    font-size: 16px;
    position: relative;
    vertical-align: middle;
    border-radius: 5px;
    z-index: 0;
    border-color: var(--primary-white);
    transition: background-color 0.5s, border-color 0.5s, backdrop-filter 0.5s;
    cursor: pointer;
}

button::before, button::after {
    border-radius: 5px !important;
}


button::before{
    top: -1px;
    left:-1px;
    /*animation: top-left-reverse linear 0.5s;*/
}

button::after{
    transform: rotate(180deg);
    bottom: -1px;
    right: -2px;
   /*animation: bottom-right-reverse linear 0.5s;*/
    
}

.descriptionContainer{
    display: grid;
    border: solid 1px var(--primary-white);
    border-radius: 10px;
    padding: 5%;
    padding-bottom: 3.5%;
    padding-right: 3%;
    padding-top: 4%;
    transition: background-color 0.5s, border-color 0.5s, backdrop-filter 0.5s;
    box-sizing: border-box;
    background-color: #00000012;
    backdrop-filter: blur(var(--blur)) brightness(110%);
    grid-template-rows: auto 25px;
    min-height: 100%;
}
.descriptionContainerContainerlol{
    position: relative;
    flex: 2 1 auto;
}



.descriptionContainer::before, .descriptionContainer::after, button::before, button::after{
    box-sizing: inherit;
    content: '';
    position: absolute;
    border: 2px solid transparent;
    width: 0;
    height: 0;
    border-radius: 10px;
}

.descriptionContainer::before{
    top: -1px;
    left:-2px;
    /*animation: top-left-reverse linear 0.5s;*/
}

.descriptionContainer::after{
    transform: rotate(180deg);
    bottom: -1px;
    right: -4px;
   /*animation: bottom-right-reverse linear 0.5s;*/
    
}

.ac-descriptionContainerHover, .buttonHover{
    border-color: transparent;
    backdrop-filter: blur(90px) brightness(99%);
}

.buttonHover{
    background-color: rgba(0, 0, 0, 0.014);
}

.ac-descriptionContainerHover::before, .ac-descriptionContainerHover::after ,.buttonHover::before, .buttonHover::after{
    transition: border-color 0.5s, width 0.5s, height 0.5s;
}

.ac-descriptionContainerHover::before, .buttonHover::before{
    animation: top-left linear 0.53s;
    animation-fill-mode: forwards;
}

.ac-descriptionContainerHover::after, .buttonHover::after{
    animation: bottom-right linear 0.33s;
    animation-fill-mode: forwards;
}

@keyframes top-left{
    0%{
      width: 0;
      height: 0;
    }
    25%{
      width: var(--hover-width);
      height: 0;
      border-top-color: var(--primary-red);
    }
    50%, 100% {
        width: var(--hover-width);
        border-top-color: var(--primary-red);
        height: var(--hover-height);
        border-right-color: var(--primary-red);
    }
   }
   
   @keyframes top-left-reverse{
    0%{
       width: 100%;
       border-top-color: var(--primary-red);
       height: 100%;
       border-right-color: var(--primary-red);
       border-top-left-radius: 5px;
      
    }
    25%{
      width: 100%;
      height: 0;
      border-top-color: var(--primary-red);
      left: 0px;
    }
    50%, 100% {
      border-color: transparent;
      width: 0;
      height: 0;
    }
   }
   
   @keyframes bottom-right{
    0%, 50%{
      border: 2px solid;
      border-color: transparent;
      width: 0;
      height: 0;
    }
    75%{
      width: var(--hover-width);
      height: 0;
      border-top-color: var(--primary-red);
    }
    100%{
        height: var(--hover-height);
        border-right-color: var(--primary-red);
       width: var(--hover-width);
       border-top-color: var(--primary-red);
    }
   }
   
   @keyframes bottom-right-reverse{
    0%, 50%{
      height: 100%;
        border-right-color: var(--primary-red);
      width: 100%;
      border-top-color: var(--primary-red);
    }
    75%{
      width: 100%;
      height: 0;
      border-top-color: var(--primary-red);
    }
    100%{
      border-color: transparent;
      width: 0;
      height: 0;
    }
   }



.tagContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap:10px;
}

.descriptionContainer h2{
    margin-top: 0.75em;
    margin-bottom: 20px !important;
    padding-right: 5%;
    font-weight: 200 !important;
}

.descriptionContainer p{
    margin: 0;
}

.tag p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.tag{
    background-color: #40404082;
    padding-top:1px;
    padding-bottom:1px;
    padding-left:8px;
    padding-right:8px;
    border-radius: 4px;
    height: 25px;
}

p.articleCardDate{
 font-weight: 600;
 font-size: 11pt;
}

.articleCardImageHover{
    transform: scale(1.1) translateX(-45%) !important;
}

.articleCardImageBorderHover{
    border: solid 2px rgba(255, 255, 255, 0.747);
}

.eventTitleLink{
    font-weight: 600;
    text-decoration: underline !important;
}


.eventContainer{
    display: flex;
    margin-top: 9%;
    margin-left: 6%;
    padding-right: 10%;
    min-width: 85%;
}

.dateBox h1{
    color: var(--primary-red);
    font-size: 40px;
    margin: 0;
    margin-top: 1px;
    margin-bottom:-5px;
    font-weight: 400;
}

.dateBox p{
    font-size: 16px;
    margin:0;
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
}

.dateBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.eventInfoMonth{
    margin-left: 1px;
    
}

.textContainer{
    margin-left: 6%;
    flex: 3;
    margin-top: 8px;
}

.textContainer h4, .textContainer p{
    margin:0;
    padding-top: 5px;
}

.textContainer h4{
    margin-top:8px;
    font-weight: 600;
    margin-bottom: 4%;
}

.noEvents{
    margin-left: 3%;
    margin-top: -5%;
}




/*---------------------------------------------------------------Home BG Image-----------------------------------------------------------------*/


.homeBg {
    position:fixed;
    background-image: url("../images/hero_pukeko.webp");
    background-position: 60% -3em;
    height: 400vh !important;
    background-attachment: fixed;
}

.backgroundImage{
    top: 0px;
    position: fixed;
    height: 130vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}


.homeSecondImg{
    z-index: 1;
    background-image: url("../images/earth.webp");
    background-position-x: 50%;
    visibility:hidden;
}

.show{
    visibility:visible;
}


.page {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    z-index: -1;
}

.newsBg{
    background-image: url("../images/rocket_launch_multiple.webp");
}





/*---------------------------------------------------------------hero---------------------------------------------------------------------*/

.hero{
    flex-direction: column;
    align-content: center;
    padding: 0;
    margin-top: 6%;
    padding-top: 0 !important; 
}

.heroHeading, .heroSubheading {
    flex: 0 1 auto;
    padding: 0;
}

.heroSpace{
    flex: 1 1 auto;
    min-height: 70px;
    padding: 1%;

}

.heroImage{
    max-width: 250px;
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
}

.heroText{
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
    --animate-delay: 1s;
    padding-top: 2%;
}

/*-------------------------------------------------------------------Error page-------------------------------------------------------------------*/

#error-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.errorContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 100%;
    height: 81vh;
    text-align: center;
    font-size: 130%;
}

.error-header{
    font-size: 400%;
    font-weight: bold;
    color: var(--primary-red);
}

/*-------------------------------------------------------------------news section-------------------------------------------------------------------*/


.homeNews{
    flex-direction: row;
    padding: 0;
    gap: 40px;
    border: none;
    background-color: transparent;
    backdrop-filter: none;
}

.homeNewsMain{
    flex: 0 1 auto;
    padding-top: 3%;
    padding-bottom: 8%;
    border-radius: 18px;
    width: 60%;
}

.homeNewsSide {
    flex: 1 1 auto;
    padding-top: 3%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 18px;
    width: 30%;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    height: auto;
    padding-bottom: 7%;
}

.postsContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
}

.homeNews .button{
    margin-top: 8%;
}

.newsHeading {
    margin-bottom: 4%;
    display: flex;
    gap: 15px;
}

.homeNews h2{
    margin-bottom: 3%;
}

.homeNewsSide h2{
    margin-left: 8%;
    margin-bottom: 12%;
}

.newsPosts h2, .upcomingEvents h2{
    font-weight: 300;
}



/*-------------------------------------------------------------------sponsors-------------------------------------------------------------------*/
.sponsors{
    visibility: hidden;
    
    border: none;
    backdrop-filter: none;
    background-color: transparent;

     gap: 20px;
}

.sponsorsLogoContainer{
    flex: 2 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 15%;
    align-items: center;
    width: 50%;
    border-radius: 18px;
    flex-wrap: wrap;
    padding: 5%;
}

.sponsorsText{
    flex: 1 1 auto;
    backdrop-filter: blur(var(--blur)) brightness(120%);
    border-radius: 18px;
    background-color: var(--bordered-background);
    border: solid 1px var(--even-thinner-white);
    width: 18%;
    padding-top: 3%;
    padding-bottom: 5%;
    padding-left: 6%;
    padding-right: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sponsorsText p, .joinClub p{
    font-weight: 200;
    line-height: 135%;
}

.sponsorsLink{
    padding-top: 0%;
    padding-bottom: 0%;
}

.sponsorLogo{
    flex: 1 1 auto;
    width: 100%;
    max-width: 250px;
    padding-top: 7%;
}


.fadeUp{
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
    visibility: visible !important;
}

.fadeDown{
    animation: fadeDown 0.15s ease-in-out; 
    opacity: 100%;
    visibility: visible !important;
}

@keyframes fadeDown{
    0%{
        margin-top: -10px;
        opacity: 0%;
    }
    100% {
        margin-top: 5px;
        opacity: 100%;
    }
}

/*-------------------------------------------------------------------join Club-------------------------------------------------------------------*/

.joinClub{
    -webkit-backdrop-filter: blur(var(--blur)) brightness(120%);
    backdrop-filter: blur(var(--blur)) brightness(120%);
    height: 350px;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
    margin-bottom: 10%;
    background-color: var(--bordered-background);
    visibility: hidden;
}



.joinButton{
    margin-top: 1%;
}

/*-------------------------------------------------------------------footer-------------------------------------------------------------------*/

.footerSection{
    display: flex;
    justify-content: center;
    height: 180px;
    background-color: var(--dark-blue);
    position: relative;
    z-index: 2;
    margin-bottom: -1%;
    margin-top: auto;
    
}

.footer{
    display: flex;
    max-width: 1920px;
    width: 80%;
    padding-left: 8%;
    padding-right: 8%;
    flex-direction: row;
}

.footer h3{
    font-size: 1.05em;
}

.footerLogoContainer{
    flex: 1 1 auto;
    display: flex;
}

.footerNavContainer{
    display: flex;
    flex: 1 1 auto;
}

.footerSocialContainer{
    display: flex;
    flex: 1 1 auto;
    gap: 15px;
    justify-content: flex-end;
}

.footerSocialContainer img:hover{
    opacity: 70%;
}

.footerSocialContainer img{
    max-width: 25px;
    cursor: pointer;
}

.discordLogo{
    margin-top: 1%;
}
















/*-------------------------------------------------------------------news page-------------------------------------------------------------------*/

.title{
    padding-top: 11%; 
    padding-bottom: 0%;
    border: none;
    background-color: transparent;
    backdrop-filter: none;
}

.titleContainer {
    padding-left: 6%;
    display: flex;
    gap: 15px;
}



.css-i4bv87-MuiSvgIcon-root:hover{
    fill: var(--primary-red);
}

      /*--------------------------------------------------------- non-desktop size -------------------------------------------------------*/


        @media all and (max-width: 1080px) {
        /*------- Mobile size Portrait ---------*/
             body{
                    overflow-x:hidden;
             }

            .homeBg{
                background-position: 65% 0%;
                height: 400vh !important;
            }

            .contactBg{
                background-position: 55% 0%;
                height: 100vh !important;
            }
        
            .section{
                max-width: 90%;
                margin-bottom: 15%;
            }

            .container, .titleContainer{
                padding: 0px;
            }

            .heroImage{
                max-width: 180px;

            }
            .sponsors, .homeNewsSide, .joinClub, .homeNewsMain{
                padding-top: 8%;
                padding-bottom: 15%;
                
            }
        
            .homeNews{
                flex-direction: column;
            }
            .homeNewsMain{
                width:100%
            }

            .homeNewsSide{
                padding-left: 0;
                padding-right: 0;
                min-width: 0;
                width: 100%;
                height: auto;
            }

            .homeNewsSide h2{
                margin-left: 0;
                margin-right: 0;
            }

            .homeNewsMain, .newsArticleList  {
                padding-top: 0%;
                background-color: transparent;
                border: none;
                -webkit-backdrop-filter: none;
                backdrop-filter: none;
            }

            .homeNews .button {
            margin-top: 1%;
            }
            


            .upcomingEvents {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .upcomingEventsContainer{
                width: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            
            .upcomingEvents h2{
                margin-left: -3%;
                margin-bottom: 0;
            }

            .eventContainer{
                margin-left: 9%;
            }

            .noEvents{
                text-align: center;
                margin-top: 3%;
                margin-left: 0;
            }
        
            .centered p{
                padding: 0 10%;
            }
        
            
            .sponsors{
                flex-direction: column;
            }

            .sponsorsLogoContainer, .sponsorsText{
                flex-direction: column;
                width: 100%;
                padding-left: 0%;
                padding-right: 0%;
                padding-top: 10%;
                padding-bottom: 15%;
                text-align: center;
            }

            .sponsorsText p, .sponsorsText h2 {
                padding-left: 10%;
                padding-right: 10%;
            }

            .sponsorsLogoContainer{
                gap: 50px;
            }
        
        
            .sponsorLogo{
                padding-top: 10%;
                width: 80%;
            }
        
        
            .joinClub{
                margin-bottom: 25%;
            }
        
        

            .title{
                padding-top: 25%; 
            }

            .imageContainer{
                max-height: 45vw;
            }

            .hero{
                margin-top: 15vw;
                margin-bottom: 0;
            }

            .buttonContainer {
                display: flex;
                justify-content: center;
                margin-top: 10%;
                width: 100%;
            }

            .buttonContainer a, .button {
                width: 100%;
            }

            .joinButton {
                margin-top: 5%;
                width: 90%;
            }
            
            .newsArticleList {
                padding-bottom: 15%;
            }

            .articleCardContainer{
                min-width: 100%;
            }
            
            .descriptionContainer h2{
                margin-top: 5%;
                margin-bottom: 8%;
            }
        }
        
       
        /* different techniques for iPad screening */
        @media all and (min-width: 481px) and (max-width: 767px) and (orientation:portrait) {
        /* For portrait layouts only */
        }
        
        @media all and (min-width: 481px) and (max-width: 767px) and (orientation:landscape) {
        /* For landscape layouts only */
        }

    
       
        @media only screen and (min-width: 767px) and (max-width: 1519px) {
            /* default iPad screens */
            .articleCardContainer {
                flex-direction: row;
                height: 190px;
                min-width: 100%;
            }

            .imageContainer{
                height: 99%;
                width: 200px;
                flex: 1 1 auto;
            }

            .descriptionContainerContainerlol{
                width:65%;
            }

        }

        @media only screen and (min-width: 1519px) and (max-width: 1919px) {
            /* default iPad screens */
            .articleCardContainer {
                flex: 0 1 300px;
            }

            .imageContainer {
                height: 200px;
            }
        }

        @media only screen and (min-width: 2290px) {
            /* default iPad screens */
            .articleCardContainer {
                flex: 0 1 340px;
            }
        }

        



