.committee {
    flex-direction: column;
    padding-top: 3%;
    padding-bottom: 7%;
    backdrop-filter: none;
}

.committeeMember{
    border-radius: 15px;
    width: 80%;
    padding: 8% 10%;
    padding-bottom: 12%;
    margin-bottom: 5%;
}

.committeeMember h2{
    margin-bottom: 15%;
}

.committeeMember h5{
    margin-top: 0;
    margin-bottom: 1em;
}

.committeeList{
    display: grid;
    gap:5%;
    padding-bottom: 1%;
    margin-top: 4%;
    grid-template-columns: 1fr 1fr 1fr;
}

.aboutUs {
    flex-direction: row;
    padding: 0;
    gap: 20px;
}

.aboutUsText{
    justify-content: center;
    border-radius: 17px;
    width: 30%;
}

.teamImage{
    border-radius: 17px;
    width: 70%;
}


.ourTeamBg{
    background-image: url("../images/space_station_windows.webp");

}

#foundingTitle{
    margin-top: 0;
}

@media all and (max-width: 1080px) {
    /*------- Mobile size Portrait ---------*/

    .ourTeamBg{
        background-position: 50% 50px;
    
    }

    .committeeMember {
        width: 80%;
        padding-top: 7%;
        padding-bottom: 15%;
        padding-left: 8%;
        margin-bottom: 0;
    }

    .committeeList {
        grid-template-columns: 1fr;
        gap: 3em;
    }

}