.openEmailButton {
    margin-top: 2%;
}

.contactBg{
    background-image: url("../images/darkbg-spaceship.webp");
}

.contactForm{
    margin-bottom: 30vh !important;
}
