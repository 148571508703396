:root {
    --primary-red: #CB0000;
    --trans-black: #10101073;
    --articleCard-trans-black: #10101082;
    --primary-white: rgb(237, 237, 237);
    --border-white: #ffffff98;
    --thin-border-white: #ffffff3d;
    --bordered-background: #e1e1e110;
    --dark-blue: #171a1c;

    --hover-width: 100%;
    --hover-height: 100%;

    --blur: 50px;
}


.navbarContainer {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    
    z-index: 5;
    animation: showNav ease-in-out 0.25s;
    animation-fill-mode: forwards;
    transition: top 0.2s, backdrop-filter 0.2s;
    width: 100vw;
    height: 65px;

}


.navbarBlur{
    /* change to blurred when top != 0 */
    -webkit-backdrop-filter: blur(var(--blur));
    backdrop-filter: blur(var(--blur));
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -10;
    opacity: 1;
    transition: opacity .2s;
}

.navbarHide{
    animation: hideNav ease-in-out 0.25s;
    animation-fill-mode: forwards;
}

@keyframes hideNav {
    0% {
        top: 0px;
    }
    100% {
        top: -100px;
    }
}

@keyframes showNav {
    0% {
        top: -100px;
    }
    100% {
        top: 0px;
    }
}


.navbar{
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    width: 80%;
    padding-left: 8%;
    padding-right: 8%;  
}

.navLogoContainer{
    flex: 1 1 auto;
    display: flex;
    padding-left: 6%;
}

.navLogo, .footerLogo{
    max-width: 130px;
    object-fit: contain;
}

.navItemContainer{
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.navItem{
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;    
    display: flex;
}

.navItem h3{
    font-weight: 300;
    font-size: 1.05em;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    letter-spacing: 0px;
}

#nav-menu-item .navItem{
    padding-left: 5%;
}

.navButton {
    width: 160px;
    margin-left: 5%;
}

.navButton-menu{
    width: 160px;
    margin-left: 2%;
    margin-top: 5%;
}

.activeLink h3{
    font-weight: 700;
}

.mobile-nav-menu{
    display: none;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-red);
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .navHamburger{
    display: none;
    }

    .animated {
        -webkit-animation-duration: 0.5s !important;
        animation-duration: 0.5s !important;
    }


    .menuIcon, .closeIcon{
        color: white;
    }

    #nav-menu-item :hover{
        background-color: var(--trans-black);
        border-radius: 5px;
    }

    .no-underline:hover{
        background-color: transparent !important;
    }


    a:link { text-decoration: none; }

    a:visited { text-decoration: none; }

    a:hover { text-decoration: none; }

    a:active { text-decoration: none; }
   
      /*--------------------------------------------------------- non-desktop size -------------------------------------------------------*/


      @media all and (max-width: 1080px) {
        /*------- Mobile size Portrait ---------*/
        
        .menu-open{
            position: fixed;
        }

        .navItem {
            display: none;
        }

        .navLogoContainer{
            padding-left: 0%;
        }

        .expanded .navItem {
            display: block;
        }

        .navHamburger{
            display: block;
        }

        .expanded{
            position: fixed;
            top:0;
            right:0;
            width:70vw;
            height: 100vh;
            background-color: rgba(65, 65, 65, 0.438);
            backdrop-filter: blur(90px);
            flex-direction: column;

            padding-left: 1%;
            padding-right: 1%;
            padding-top: 5%;
            
            animation: fadeInRight; 
            animation-duration: 0.5s; 

            visibility: visible;
            display: flex !important;
        }

        .navMenuCloseArea{
            position: fixed;
            top: 0;
            height: 100vh;
            width: 25vw;
            padding-left: 5%;
            padding-right: 5%;
            
        }

        .mobile-nav-menu{
            position: fixed;
            top:0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.705);
            z-index: 20;

            animation: fadeIn; 
            animation-duration: 0.2s;

        }

        .open{
            visibility: visible;
            display: flex !important;
            pointer-events: auto;
            overflow-y: hidden;
        } 

        .not {
            animation: fadeOutRight; 
            animation-duration: 0.35s; 
            animation-fill-mode: forwards;

            visibility: visible;
            display: flex !important;
        }

        .closed{
            animation: fadeOut;
            animation-duration: 0.35s; 
            animation-fill-mode: forwards;

            visibility: visible;
            display: flex !important;

            pointer-events: none;
        }

        .hidden {
            display: none;
        }

      }